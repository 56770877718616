import "./PageError.scss";

function PageError() {

    return (
        <section id="page-error">
                <h2>404<br />Page introuvable</h2>
        </section>

    );
}

export default PageError;